<template>
  <ul class="navigation-list">
    <template v-if="chosen">
      <div
          class="chosen-name d-flex"
      >
        <v-icon
            class="mr-1 back-arrow"
            color="rgb(74, 78, 105)"
            @click="backList"
        >
          mdi-backburger
        </v-icon>
        <span>{{ chosen.item.name }}</span>
      </div>

      <template v-if="chosen.type === 'tectonic'">
        <DepositItem
            v-for="node in tectonicDeposits(chosen.item.id)"
            :key="node.name"
            :item="node"
            :depth="depth"
        />
      </template>

      <template v-if="chosen.type === 'deposit'">
        <WellItem
            v-for="well in depositWells(chosen.item.id)"
            :key="well.id"
            :item="well"
            :depth="depth"
        />
      </template>

      <template v-if="chosen.type === 'well'">
        <BaseListItem
            :item="chosen.item"
            :depth="depth"
            :child="wellSamples(chosen.item.id)"
            :sub-list="true"
            :shower="'showSample'"
            :is-context-clickable="false"
        >
          <template v-slot:title>
            Образцы
          </template>

          <template v-slot:sub-list>
            <SampleItem
                v-for="sample of wellSamples(chosen.item.id)"
                :key="sample.id"
                :item="sample"
                :depth="depth + 1"
            ></SampleItem>
          </template>
        </BaseListItem>
      </template>

      <template v-if="chosen.type === 'sample'">
        <ThinSectionItem
            v-for="thinSection of sampleThinSections(chosen.item.id)"
            :key="thinSection.id"
            :item="thinSection"
            :depth="depth"
        ></ThinSectionItem>
      </template>

      <template v-if="chosen.type === 'thin-section'">
        <BaseListItem
            :item="chosen.item"
            :depth="depth"
            :child="thinSectionPlots(chosen.item.id)"
            :sub-list="true"
            :shower="'show'"
            :is-context-clickable="false"
        >
          <template v-slot:title>
            Участки шлифа
          </template>

          <template v-slot:sub-list>
            <ThinSectionPlotItem
                v-for="thinSectionPlot of thinSectionPlots(chosen.item.id)"
                :key="thinSectionPlot.id"
                :item="thinSectionPlot"
                :depth="depth + 1"
            ></ThinSectionPlotItem>
          </template>
        </BaseListItem>
      </template>


    </template>

    <template v-else>
      <TectonicItem
          v-for="item in tectonics"
          :key="item.id"
          :item="item"
          :depth="depth"
      />
    </template>
  </ul>
</template>

<script>
import TectonicItem from "@/components/list/TectonicItem";

import {mapGetters, mapMutations} from 'vuex'
import DepositItem from "@/components/list/DepositItem";
import WellItem from "@/components/list/WellItem";
import BaseListItem from "@/components/list/BaseListItem";
import SampleItem from "@/components/list/SampleItem";
import ThinSectionItem from "@/components/list/ThinSectionItem";
import ThinSectionPlotItem from "@/components/list/ThinSectionPlotItem";


export default {
  name: "NavigationList",
  components: {
    ThinSectionPlotItem,
    ThinSectionItem,
    SampleItem,
    BaseListItem,
    WellItem,
    DepositItem,
    TectonicItem,
  },
  data() {
    return {
      depth: 0
    }
  },
  methods: {
    ...mapMutations(['REMOVE_CHOSEN_ITEMS']),
    async navigate(link) {
      await this.$router.push(link)
    },
    backList() {
      this.REMOVE_CHOSEN_ITEMS();
    }
  },
  computed: {
    ...mapGetters([
      'tectonics',
      'chosen',
      'tectonicDeposits',
      'depositWells',
      'wellSamples',
      'sampleThinSections',
      'thinSectionPlots',
    ])
  },
  async mounted() {
    console.log('list mounted')
  }
}
</script>

<style scoped lang="scss">

.navigation-list {
  padding-left: 0;
  list-style-type: decimal;
}

.chosen-name {
  font-size: 20px;
  text-align: center;
  padding: .5rem;
  font-weight: bold;
}

.back-arrow:hover {
  border-radius: .3rem;
  background-color: rgba(84, 80, 80, 0.25);
}
</style>